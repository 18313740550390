/* eslint-disable no-param-reassign */
import { App as Application } from 'vue';
import axios from 'axios';
import store from '@/store';
import { USER_ROLE } from 'enum';
import { IReferralProgram } from '@/core/types/referralProgram';

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/`;

class ApiService {
    get role(): string {
        return store.getters.getAdminConsoleUserData.role || USER_ROLE.SUPPORT;
    }

    // USER / USERS

    getUser(params = {}): Promise<void> {
        return axios.get(`${this.role}/user`, params);
    }

    getUsers(params = {}): Promise<void> {
        return axios.get(`${this.role}/user/search`, params);
    }

    changeUserData(userId: string, params = {}): Promise<void> {
        return axios.patch(`${this.role}/user/${userId}`, params);
    }

    changeUserLicence(userId: string, params = {}): Promise<void> {
        return axios.patch(`${this.role}/user/${userId}/subscription`, params);
    }

    resetUserPortfolioCache(userId: string): Promise<void> {
        return axios.post(`${this.role}/user/${userId}/portfolio/reset`);
    }

    // STRATEGIES

    getUserStrategy(strategyId: string): Promise<void> {
        return axios.get(`${this.role}/user/bots/${strategyId}`).then((res) => res.data.data);
    }

    getUserStrategies(params = {}): Promise<void> {
        return axios.get(`${this.role}/user/bots`, params).then((res) => res.data.data);
    }

    // PORTFOLIO

    getUserPortfolio(userId: string, params = {}): Promise<void> {
        return axios.get(`${this.role}/user/${userId}/portfolio`, params);
    }

    // USER REFERRALS

    getUserReferrals(userId: string, params = {}): Promise<void> {
        return axios.get(`${this.role}/user/${userId}/referrals`, params);
    }

    // LOYALTY

    getUserLoyaltyHistory(userId: string, params = {}): Promise<void> {
        return axios.get(`${this.role}/user/${userId}/loyalty/history`, params);
    }

    addUserLoyaltyAccountRecord(userId: string, params = {}): Promise<void> {
        return axios.post(`${this.role}/user/${userId}/loyalty/manual`, params);
    }

    // TELEGRAM ANG EMAIL MESSAGE

    sendNewTelegramMsg(userId: string, params = {}): Promise<void> {
        return axios.post(`${this.role}/user/${userId}/telegram/message`, params);
    }

    sendNewGroupTelegramMsg(params = {}): Promise<void> {
        return axios.post(`${this.role}/user/telegram/message/send/group`, params);
    }

    sendNewGroupEmailMsg(params = {}): Promise<void> {
        return axios.post(`${this.role}/user/email/message/send/group`, params);
    }

    // TELEGRAM BOTS

    getTelegramBots(): Promise<void> {
        return axios.get(`${this.role}/telegram`);
    }

    createNewTelegramBot(params = {}): Promise<void> {
        return axios.post(`${this.role}/telegram`, params);
    }

    // REFERRALS

    getReferrals(): Promise<void> {
        return axios.get(`${this.role}/referrals`);
    }

    getReferral(id: string): Promise<void> {
        return axios.get(`${this.role}/referrals/${id}`);
    }

    createNewReferral(params = {}): Promise<void> {
        return axios.post(`${this.role}/referrals`, params);
    }

    disableReferral(id: string): Promise<void> {
        return axios.delete(`${this.role}/referrals/${id}`);
    }

    getReferralPrograms(disabled: boolean = false): Promise<IReferralProgram[]> {
        return axios.get(`${this.role}/referrals/programs?disabled=${disabled}`)
                .then((response) => response.data.data);
    }

    getReferralProgramById(programId: string): Promise<IReferralProgram[]> {
        return axios.get(`${this.role}/referrals/programs/${programId}`)
                .then((response) => response.data.data);
    }

    createReferralProgram(data: IReferralProgram): Promise<IReferralProgram> {
        return axios.post(`${this.role}/referrals/programs`, data)
                .then((response) => response.data.data);
    }

    updateReferralProgram(data: IReferralProgram): Promise<IReferralProgram> {
        return axios.patch(`${this.role}/referrals/programs/${data.id}`, data)
                .then((response) => response.data.data);
    }

    // FEATURES

    getFeatures(userId: string): Promise<void> {
        return axios.get(`${this.role}/user/${userId}/features`);
    }

    getUserSettings(userId: string): Promise<void> {
        return axios.get(`${this.role}/user/${userId}/settings`);
    }

    updateUserSetting(userId: string, settingName: string, params = {}): Promise<void> {
        return axios.patch(`${this.role}/user/${userId}/settings/${settingName}`, params);
    }

    // LOGS

    getPortfolioLogs(userId: string, params = {}): Promise<void> {
        return axios.get(`${this.role}/logs/portfolio/${userId}`, params);
    }

    getStrategyLogs(strategyId: string, params = {}): Promise<void> {
        return axios.get(`${this.role}/logs/bot/${strategyId}`, params);
    }

    // BILLING

    getActiveSubscriptionsList(): Promise<void> {
        return axios.get(`${this.role}/subscription/active/list`);
    }

    createPromoCode(params = {}): Promise<void> {
        return axios.post(`${this.role}/subscription/promocode`, params);
    }

    readPromoCode(code: string): Promise<void> {
        return axios.get(`${this.role}/subscription/promocode/${code}`);
    }

    deletePromoCode(code: string): Promise<void> {
        return axios.delete(`${this.role}/subscription/promocode/${code}`);
    }

    getUserCurrentSubscription(userId: string): Promise<void> {
        return axios.get(`${this.role}/user/${userId}/subscription`);
    }

    // RECEIPTS

    getReceipts(params = {}): Promise<void> {
        return axios.get(`${this.role}/subscriptions/receipts`, params);
    }

    downloadReceipt(params = {}): Promise<void> {
        return axios.get(`${this.role}/subscriptions/receipts/download`, params);
    }
}

export default (app: Application): void => {
    // @ts-ignore
    app.axios = axios;
    app.config.globalProperties.$api = new ApiService();
};
